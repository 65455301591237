(function($) {
    $(function() {
        /**
         * Animate login button on click and go to home or reset on error.
         * @return {void}
         */
        $('#auth-form').on('submit', function(e) {
            // Don't go to home on login.
            e.preventDefault();

            var submitWrapper = $('.submit-button'),
                loginInputs = $('.auth-input'),
                successWrapper = $('.checkmark-svg'),
                successLines = $('.checkmark'),
                errorWrapper = $('.cross-svg'),
                errorLines = $('.cross'),
                backButton = $('a.back');

            // Styling the loading animation circle with Progressbar JS.
            var circle = new ProgressBar.Circle('.submit-button button', {
                strokeWidth: 11,
                easing: 'easeInOut',
                fill: 'transparent'
            });

            // Add loading class to the button, lighten the login inputs
            // and start animating the circle to 70%.
            var startLoadingAnimation = function(callback) {
                submitWrapper.addClass('loading');
                backButton.animate({
                    opacity: '0.2'
                }, 500);
                loginInputs.animate({
                    opacity: '0.2'
                }, 500, function() {
                    circle.animate(0.7, callback);
                });
            };

            // Check if the login data are correct.
            var doRequest = function() {
                return $.post(config.baseUrl + '/login', {
                    email: $('[name="email"]').val(),
                    password: $('[name="password"]').val(),
                    _token: $('[name="_token"]').val()
                });
            };

            // Finish animating the circle to 100%.
            var finishLoadingAnimation = function(callback) {
                circle.animate(1, callback);
            };

            // Show success checkmark and wait 1.5s before next function.
            var showCheckMark = function(callback) {
                setTimeout(callback, 1500);
                successWrapper.css('opacity', 1);
                successLines.css('stroke-dashoffset', 0);
            };

            // Show error cross and wait 1.5s before next function.
            var showCross = function(callback) {
                setTimeout(callback, 1500);
                errorWrapper.css('opacity', 1);
                errorLines.css('stroke-dashoffset', 0);
            };

            // Go to home.
            var redirectToHomepage = function() {
                window.location = config.baseUrl + '/home';
            };

            // Reset login button and form to original state.
            var resetForm = function() {
                $('svg').css('opacity', 0);
                circle.animate(0);
                errorLines.animate({
                    'stroke-dashoffset': '130px'
                }, 0, function() {
                    submitWrapper.removeClass('loading');
                    backButton.animate({
                        opacity: '1'
                    }, 300);
                    loginInputs.animate({
                        opacity: '1'
                    }, 300);
                });
            };

            // Start animating.
            startLoadingAnimation(function() {
                var request = doRequest();

                request.done(function(result) {
                    finishLoadingAnimation(function() {
                        showCheckMark(function() {
                            redirectToHomepage();
                        });
                    });
                });

                request.error(function(jqXhr) {
                    finishLoadingAnimation(function() {
                        showCross(function() {
                            resetForm();
                        });
                    });
                });
            });
        });
    });
}(jQuery));